import axios from "../../../../../../config/axios";
import configConstants from "../../../../../../config/constants";

const sliderService = {
  getImages: async (id="latest", params={}) => {
    try {
      const apiUrl = configConstants.apiUrlWithPort;
      const res = await axios.get(
        `${apiUrl}/layout/slider/getImages`,
        {
          params: params,
        }
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  },
};

export default sliderService;