import {
  Box,
  Button,
  Container,
  Divider,
  Menu,
  Stack,
  styled,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Colors } from "../../../../../styles/theme";
import CallingIcon from "../../../../../components/icons/CallingIcon";
import Logo from "../../../../../components/logo/logo";
import RoundButton from "../../../../../components/ui-conponents/RoundButton";
import Drawer from "./drawerComp";
import { useTranslation, withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AppbarBody = ({ isMobile, i18n }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.only("xl"));
  const isLG = useMediaQuery(theme.breakpoints.only("lg"));
  const isMD = useMediaQuery(theme.breakpoints.only("md"));
  const isMMD = useMediaQuery(theme.breakpoints.only("mmd"));
  const isSM = useMediaQuery(theme.breakpoints.only("sm"));
  const isXS = useMediaQuery(theme.breakpoints.only("xs"));
  const [height, setHeight] = useState(480);
  const [logoMargin, setLogoMargin] = useState(10);
  const [padding, setPadding] = useState(48);
  const homeHandler = () => {
    navigate("/");
  };
  const investHandler = ()=>{
    navigate("/invest");
  }

  useEffect(() => {
    if (isXL) {
      setHeight(115);
      setPadding(48);
      setLogoMargin(84);
    } else if (isLG) {
      setHeight(84);
      setPadding(48);
      setLogoMargin(48);
    } else if (isMD) {
      setHeight(72);
      setPadding(36);
      setLogoMargin(36);
    } else if (isSM) {
      setHeight(60);
      setPadding(12);
    } else if (isXS) {
      setHeight(60);
      setPadding(12);
    }
  }, [isLG, isMD, isSM, isXL, isXS, isMMD]);
  const pages = [
    { id: "home", title: t("header.appbarBody.title1"), onClickHandler:()=>{navigate("/")} },
    { id: "services", title: t("header.appbarBody.title2"), onClickHandler:()=>{navigate("/products")} },
    { id: "howToInvest", title: t("header.appbarBody.title3"), onClickHandler:()=>{navigate("/invest")} },
  ];


  return (
    <Toolbar
      sx={{
        color: Colors.primary,
        justifyContent: { xs: "space-between", md: "" },
      }}
      style={{
        minHeight: height + "px",
        background: "#ffffff",
        paddingLeft: padding + "px",
        paddingRight: padding + "px",
      }}
    >
      <Logo
        sx={{ mr: logoMargin + "px", display: { xs: "none", md: "flex" } }}
        onClick={() => {
          navigate("/");
        }}
      />
      <Stack
        direction="row"
        sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
      >
        {pages.map((page, index) => {
          return (
            <Box sx={{ display: "flex" }} key={page.id}>
              {index > 0 ? (
                <Divider
                  orientation="vertical"
                  variant="middle"
                  style={{ height: height / 2 }}
                  sx={{ mx: 3, borderRightWidth: "medium" }}
                />
              ) : null}
              <Button
                color="inherit"
                sx={{
                  display: "flex",
                }}
                onClick={page.onClickHandler}
              >
                {i18n.language === "ta" ? (
                  <Typography variant="menu_ta" noWrap={true}>
                    {page.title}
                  </Typography>
                ) : i18n.language === "si" ? (
                  <Typography variant="menu_si" noWrap={true}>
                    {page.title}
                  </Typography>
                ) : (
                  <Typography variant="menu" noWrap={true}>
                    {page.title}
                  </Typography>
                )}
              </Button>
            </Box>
          );
        })}
      </Stack>
      <Drawer sx={{ display: { xs: "flex", md: "none" } }} />
      <RoundButton
        variant="contained"
        startIcon={<CallingIcon sx={{ mr: 1 }} />}
        sx={{
          display: { xs: "none", md: "flex" },
          backgroundColor: Colors.primary,
          p: 1,
          pl: 4,
          pr: 4,
          fontFamily: "YouTubeSansSemibold",
          fontSize: "25px",
        }}
      >
        <Typography
          variant="menu"
          sx={{ color: "#EBEBEB", fontSize: { xs: "18px", md: "20px" } }}
        >
          +94(11)7602000
        </Typography>
      </RoundButton>
    </Toolbar>
  );
};

export default withTranslation()(AppbarBody);
