import {
  Box,
  Container,
  Menu,
  styled,
  Toolbar,
  Typography,
  Divider,
  Grid,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./footerTop.scss";
import LocationIcon from "../../../../asserts/footerIcons/locationIcon.svg";
import MobileIcon from "../../../../asserts/footerIcons/mobileIcon.svg";
import PhoneIcon from "../../../../asserts/footerIcons/phoneIcon.svg";
import EmailIcon from "../../../../asserts/footerIcons/emailIcon.png";
import FacebookIcon from "../../../../asserts/footerIcons/facebookIcon.svg";
import TwitterIcon from "../../../../asserts/footerIcons/twitterIcon.svg";
import YoutubeIcon from "../../../../asserts/footerIcons/youtubeIcon.svg";
import LinkedinIcon from "../../../../asserts/footerIcons/linkedinIcon.svg";
import { useTranslation, withTranslation } from "react-i18next";

const FooterTop = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.only("xl"));
  const isLG = useMediaQuery(theme.breakpoints.only("lg"));
  const isMD = useMediaQuery(theme.breakpoints.only("md"));
  const isSM = useMediaQuery(theme.breakpoints.only("sm"));
  const isXS = useMediaQuery(theme.breakpoints.only("xs"));
  const [height, setHeight] = useState(38);
  const [width, setWidth] = useState(38);

  useEffect(() => {
    if (isXL) {
      setHeight(38);
      setWidth(38);
    } else if (isLG) {
      setHeight(35);
      setWidth(35);
    } else if (isMD) {
      setHeight(30);
      setWidth(30);
    } else if (isSM) {
      setHeight(28);
      setWidth(28);
    } else if (isXS) {
      setHeight(25);
      setWidth(25);
    }
  }, [isLG, isMD, isSM, isXL, isXS]);

  const pages = [
    { id: "downloads", title: t("footer.footerTop.pages.title1") },
    { id: "F&Q", title: t("footer.footerTop.pages.title2") },
    // { id: "quickLinks", title: t("footer.footerTop.pages.title3")  },
    // { id: "pictureGallery", title: t("footer.footerTop.pages.title4")  },
    { id: "annualReports", title: t("footer.footerTop.pages.title5") },
    { id: "privacyPolicy", title: t("footer.footerTop.pages.title6") },
  ];
  const details = [
    {
      id: "location",
      title: t("footer.footerTop.details.address"),
      icon: LocationIcon,
    },
    { id: "phoneNumber", title: "+94 117 602000", icon: MobileIcon },
    { id: "landNumber", title: "+94 112 327203 / 7604000", icon: PhoneIcon },
    { id: "email", title: "info@ceybank.com", icon: EmailIcon },
  ];

  return (
    <footer>
      <Box
        px={{ xs: 3, sm: 10 }}
        py={{ xs: 5, sm: 2 }}
        bgcolor="#000B4E"
        color="#E2E2E2"
      >
        <Container sx={{paddingTop:'12px', paddingBottom:'12px'}}>
          <Box sx={{display: { xs: "inline", md: "flex" }, justifyContent:'center'}} >
            {pages.map((pages) => {
              return (
                <Box className="titlePages">
                  {pages.title}
                </Box>
              );
            })}
          </Box>
          {/* <Typography className="pages"> */}

          {/* </Typography> */}
        </Container>
        <Divider
          style={{
            width: "100%",
            backgroundColor: "#E2E2E2",
            marginTop: "24px",
            marginBottom: "24px",
          }}
        />
        <Box className="BoxContainerDetails">
          <Typography className="details">
            {details.map((details) => {
              return (
                <Box className="detailsBox">
                  <img className="detailIcons" src={details.icon} />
                  <div className="titleDetails"> {details.title}</div>
                </Box>
              );
            })}
          </Typography>
        </Box>
        <Box className="BoxContainerSocialIcons">
          <div>
            <img
              className="SocialIcons"
              height={height}
              width={width}
              src={FacebookIcon}
            />
            <img
              className="SocialIcons"
              height={height}
              width={width}
              src={TwitterIcon}
            />
            <img
              className="SocialIcons"
              height={height}
              width={width}
              src={YoutubeIcon}
            />
            <img
              className="SocialIcons"
              height={height}
              width={width}
              src={LinkedinIcon}
            />
          </div>
        </Box>
      </Box>
    </footer>
  );
};

export default withTranslation()(FooterTop);
