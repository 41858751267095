import { useRoutes } from "react-router-dom";

import PageRoutes from "./pageRoutes";
import CustomRoutes from "./CustomRoutes";

const Routes = () => {
    return useRoutes([
        PageRoutes,
        CustomRoutes
    ])
}


export default Routes;