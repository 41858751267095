import React, { useState } from "react";
import { Outlet } from "react-router-dom";

// material-ui

// Project Imports
import Header from "./header";
import FullWithContainer from "../../components/ui-conponents/fullWidthContainer";
import Footer from "./footer";
import { Container, useMediaQuery, useTheme } from "@mui/material";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const bodyClickHandler = (event) => {
    if (event.target.classList.contains("userProfile")) {
      setIsProfileOpen(!isProfileOpen);
    } else {
      if (isProfileOpen) {
        setIsProfileOpen(!isProfileOpen);
      }
    }
  };
  return (
    <FullWithContainer sx={{ background: "#fff" }}>
      <Header />
      <Container
        style={{
          paddingLeft: isMobile ? "24px" : "48px",
          paddingRight: isMobile ? "24px" : "48px",
        }}
        maxWidth="100"
      >
        <Outlet />
      </Container>
      <Footer />
    </FullWithContainer>
  );
};

export default MainLayout;
