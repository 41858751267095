import React from "react";
import { lazy } from "react";

// Project Imports

import Loadable from "../../components/utils/Loadable";
import MinimalLayout from "../../layouts/minimalLayout";
// Renders
const Home = Loadable(lazy(() => import("../../pages/home/home")));
const AboutUs = Loadable(lazy(() => import("../../pages/aboutUs/AboutUs")));
const Contact = Loadable(lazy(() => import("../../pages/contact/contact")));
const Staff = Loadable(lazy(() => import("../../pages/staff/staff")));
const UnitPrices = Loadable(lazy(() => import("../../pages/unitPrices")));
const FundReturns = Loadable(lazy(() => import("../../pages/fundReturns")));
const Invest = Loadable(lazy(() => import("../../pages/invest")));
const SelectFunds = Loadable(lazy(() => import("../../pages/selectFunds")));
const Funds = Loadable(lazy(() => import("../../pages/selectFunds/funds")));
const UnitTrustFund = Loadable(
  
  lazy(() => import("../../pages/selectFunds/unitTrustFund"))
);
const Products = Loadable(lazy(() => import('../../pages/products')));

const CustomRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/unitPrices",
      element: <UnitPrices />,
    },
    {
      path: "/fundReturns",
      element: <FundReturns />,
    },
    {
      path: "/invest",
      element: <Invest />,
    },
    {
      path: "/selectFunds",
      element: <SelectFunds />,
      // children: [
      //     {
      //         path: '/unitTrustFund',
      //         element: <UnitTrustFund />
      //     }
      // ]
    },
    {
      path: "/selectFunds/:id",
      element: <Funds />,
    },
    {
      path: "/Staff",
      element: <Staff />,
    },
    {
      path: "/products",
      element: <Products />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
  ],
};

export default CustomRoutes;
