import { createTheme } from "@mui/material";

export const Colors = {
  primary: "#222C6A",
  secondary: "#d1adcc",
  success: "#4CAF50",
  info: "#00a2ff",
  danger: "#FF5722",
  warning: "#FFC107",
  dark: "#0e1b20",
  light: "#aaa",
  muted: "#abafb3",
  border: "#DDDFE1",
  inverse: "#2F3D4A",
  shaft: "#333",
  ///////////////
  // Grays
  ///////////////
  dim_grey: "#696969",
  dove_gray: "#d5d5d5",
  body_bg: "#f3f6f9",
  light_gray: "rgb(230,230,230)",
  ///////////////
  // Solid Color
  ///////////////
  white: "#fff",
  black: "#000",
  fonts: {
    primary: "#222C6A",
    secondary: "#525252",
  },
  risk:{
    high: '#F94545',
    modarateHigh: '#FF520A',
    modarate: '#CC692C',
    modarateLow: '#00DD67',
    low: '#01843E'
  }
};
const theme = createTheme();
console.log(theme.breakpoints);
theme.breakpoints = {
  ...theme.breakpoints,
  values: {
    ...theme.breakpoints.values,
    sm: 800,
  },
};
theme.palette = {
  ...theme.palette,
  primary: {
    main: Colors.primary,
  },
  whiten: {
    main: "#fff",
    contrastText: "#fff",
  },
};

theme.components = {
  ...theme.components,
  MuiContainer: {
    styleOverrides: {
      // Name of the slot
      root: {
        // Some CSS
        [theme.breakpoints.only("xl")]: {
          maxWidth: "95%",
        },
        [theme.breakpoints.only("lg")]: {
          maxWidth: "95%",
        },
        [theme.breakpoints.only("md")]: {
          maxWidth: "95%",
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      disableElevation: true,
    },
  },
  MuiList: {
    styleOverrides: {
      // Name of the slot
      root: {
        // Some CSS
        fontSize: '1rem',
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      // Name of the slot
      root: {
        color: Colors.fonts.secondary,
        alignItems: 'baseline',
        // Some CSS
        [theme.breakpoints.only("xl")]: {
          fontSize: "26px",
        },
        [theme.breakpoints.only("lg")]: {
          fontSize: "24px",
        },
        [theme.breakpoints.only("md")]: {
          fontSize: "22px",
        },
      },
    },
  },
};

theme.typography = {
  ...theme.typography,
  body1: {
    fontFamily: "YouTubeSansRegular",
    color: Colors.fonts.secondary,
    fontSize: "18px",
    textAlign: "justify",
    marginBottom: "24px",
    [theme.breakpoints.only("xl")]: {
      fontSize: "26px",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "24px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "22px",
    },
  },
  menu: {
    textTransform: "capitalize",
    fontFamily: "YouTubeSansSemibold",
    fontSize: "24px",
    [theme.breakpoints.only("xl")]: {
      fontSize: "24px",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "18px",
    },
  },
  menu_ta: {
    fontWeight: "bold",
    textTransform: "capitalize",
    fontFamily: "YouTubeSansSemibold",
    fontSize: "24px",
    [theme.breakpoints.only("xl")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "18px",
    },
  },
  menu_si: {
    fontWeight: "bold",
    textTransform: "capitalize",
    fontFamily: "YouTubeSansSemibold",
    fontSize: "24px",
    [theme.breakpoints.only("xl")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "18px",
    },
  },
  title: {
    fontFamily: "YouTubeSansBold",
    fontSize: "28px",
    paddingTop: "18px",
    paddingBottom: "18px",
    textAlign: "center",
    color: Colors.primary,

    [theme.breakpoints.only("xl")]: {
      fontSize: "42px",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "42px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "36px",
    },
  },
  title1: {
    fontFamily: "YouTubeSansBold",
    fontSize: "28px",
    // marginTop:'18px',
    marginBottom: '24px',
    textAlign: "center",
    color: Colors.primary,

    [theme.breakpoints.only("xl")]: {
      fontSize: "30px",
      // marginTop:'24px',
      // marginBottom: '24px'
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "24px",
      // marginTop:'24px',
      // marginBottom: '24px'
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "24px",
      // marginTop:'18px',
      // marginBottom: '18px'
    },
  },
  title2: {
    fontFamily: "YouTubeSansMedium",
    fontSize: "28px",
    // marginTop:'18px',
    marginBottom: '24px',
    textAlign: "center",
    color: Colors.primary,

    [theme.breakpoints.only("xl")]: {
      fontSize: "30px",
      // marginTop:'24px',
      // marginBottom: '24px'
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "24px",
      // marginTop:'24px',
      // marginBottom: '24px'
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "24px",
      // marginTop:'18px',
      // marginBottom: '18px'
    },
  },
  title3: {
    fontFamily: "YouTubeSansMedium",
    fontSize: "14px",
    marginTop: "18px",
    marginBottom: "18px",
    textAlign: "center",
    color: Colors.primary,

    [theme.breakpoints.only("xl")]: {
      fontSize: "20px",
      marginTop: "24px",
      marginBottom: "24px",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "18px",
      marginTop: "24px",
      marginBottom: "24px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "16px",
      marginTop: "18px",
      marginBottom: "18px",
    },
  },
  title4: {
    fontFamily: "YouTubeSansMedium",
    fontSize: "14px",
    marginTop: 20,
    marginBottom: "18px",
    textAlign: "center",
    color: Colors.white,

    [theme.breakpoints.only("xl")]: {
      fontSize: "20px",
      marginTop: "24px",
      marginBottom: "24px",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "18px",
      marginTop: "24px",
      marginBottom: "24px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "16px",
      marginTop: "18px",
      marginBottom: "18px",
    },
  },
  title4_ta: {
    fontFamily: "YouTubeSansMedium",
    fontSize: "14px",
    marginTop: 20,
    marginBottom: "18px",
    textAlign: "center",
    color: Colors.white,

    [theme.breakpoints.only("xl")]: {
      fontSize: "18px",
      marginTop: "12px",
      marginBottom: "24px",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "16px",
      marginTop: "12px",
      marginBottom: "24px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "14px",
      marginTop: "12px",
      marginBottom: "18px",
    },
  },
  title_table: {
    fontFamily: "YouTubeSansMedium",
    fontSize: "20px",
    color: Colors.primary
  },
  title_branchLocator: {
    fontFamily: "YouTubeSansBold",
    paddingTop: "18px",
    paddingBottom: "18px",
    color: Colors.primary,
    fontSize: "25px",
    [theme.breakpoints.only("xs")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "25px",
    },
  },
  title_branchLocator_sub: {
    fontFamily: "YouTubeSansBold",
    paddingBottom: "1px",
    color: Colors.black,
    fontSize: "22px",

    [theme.breakpoints.only("xs")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "22px",
    }
  },
  titleServices: {
    fontFamily: "YouTubeSansBold",
    fontSize: "14px",
    marginTop: 20,
    marginBottom: "18px",
    textAlign: "center",
    color: Colors.white,

    [theme.breakpoints.only("xl")]: {
      fontSize: "22px",
      marginTop: "50px",
      marginBottom: "24px",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "18px",
      marginTop: "50px",
      marginBottom: "24px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "16px",
      marginTop: "50px",
      marginBottom: "18px",
    },
  },
};
console.log(theme.breakpoints);
export default theme;
