import React, { useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import logo from '../../asserts/images/logo.jpg';

export default function Logo(props) {
  const theme = useTheme();
    const isXL = useMediaQuery(theme.breakpoints.only('xl'));
    const isLG = useMediaQuery(theme.breakpoints.only('lg'));
    const isMD = useMediaQuery(theme.breakpoints.only('md'));
    const isSM = useMediaQuery(theme.breakpoints.only('sm'));
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));
    const [height, setHeight] = useState(480);
    
    useEffect(()=>{
        if(isXL){
            setHeight(84);
        }else if(isLG){
            setHeight(72)
        }else if(isMD){
            setHeight(48)
        }else if(isSM){
            setHeight(36)
        }else if(isXS){
            setHeight(36)
        }
    }, [isLG,isMD,isSM,isXL,isXS]);
    // 
  return (
    <Box {...props}>
      <img src={logo} height={height+'px'} className="icon" />
    </Box>
  );
}