import React from "react";
import FullWithContainer from "../../../components/ui-conponents/fullWidthContainer";
import FooterBottom from "./components/footerBottom";
import FooterTop from "./components/footerTop";

const Footer = ()=>{
    return (
        <FullWithContainer>
            <FooterTop />
            <FooterBottom />
        </FullWithContainer>
    );
}

export default Footer;