import { Button } from '@mui/material';
import React from 'react';

const RoundButton = (props)=>{
    return (
        <Button style={{boxSizing: 'border-box'}} {...props} sx={{...props.sx,
            borderRadius: 10
        }}>
            {props.children}
        </Button>
    );
}

export default RoundButton;