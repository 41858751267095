import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import AppbarHeader from './appbarHeader';
import AppbarBody from './appbarBody';
import FullWithContainer from '../../../../../components/ui-conponents/fullWidthContainer';

const pages = ['Home', 'Product & Services', 'How to invest', 'Statistic'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const ResponsiveAppBar = ({isMobile}) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" sx={{
        background: 'transparent',
        color:'#fff'
    }}
    elevation={0}
    >
      <FullWithContainer maxWidth="100" style={{
        paddingLeft:0,
        paddingRight:0
      }}>
        <AppbarHeader />
        <AppbarBody isMobile={isMobile}/>
      </FullWithContainer>
    </AppBar>
  );
};
export default ResponsiveAppBar;
