import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ProfitsIcon from "../../../../../asserts/images/slider/leftIcons/profitsIcon.png";
import ReturnOnInvestment from "../../../../../asserts/images/slider/leftIcons/return-on-investment.png";
import Investment from "../../../../../asserts/images/slider/leftIcons/investment-sider.png";
import Fund from "../../../../../asserts/images/slider/leftIcons/fund.png";
import { Colors } from "../../../../../styles/theme";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import featureService from "./services/featureService";
import { withTranslation } from "react-i18next";

const Fetures = ({ i18n, isMobile, height }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.only("xl"));
  const isLG = useMediaQuery(theme.breakpoints.only("lg"));
  const isMD = useMediaQuery(theme.breakpoints.only("md"));
  const isSM = useMediaQuery(theme.breakpoints.only("sm"));
  const isXS = useMediaQuery(theme.breakpoints.only("xs"));
  const [imgHeight, setImgHeight] = useState(480);
  const [images, setImages] = useState([]);
  const [fundReturnsImages, setFundReturnsImages] = useState({});
  const [investImages, setInvestImages] = useState({});
  const [selectFundsImages, setSelectFundsImages] = useState({});
  const [currentImages, setCurrentImages] = useState({
    imgUnitPrice: null,
    imgFundReturn: null,
    imgInvest: null,
    imgSelectFund: null,
  });

  useEffect(() => {
    async function init() {
      if (images.length == 0) {
        const res = await featureService.getImages({});
        setImages(
          res.map((i) => {
            return {
              imageTitle: i.imageTitle,
              imageUrl: i.imageUrl,
              langCode: i.langCode,
              imageId: i.imageId,
            };
          })
        );
        const cimgs = res.filter((i) => {
          if(i.langCode == i18n.language){
            return i
          }
        });
        const updatedCurrentImages = {...currentImages};
        cimgs.forEach(img => {
          updatedCurrentImages[img.imageId] = img.imageUrl
        });  
        setCurrentImages(updatedCurrentImages);
      } else {
        const cimgs = images.filter((i) => {
          if(i.langCode == i18n.language){
            return i
          }
        });
        const updatedCurrentImages = {...currentImages};
        cimgs.forEach(img => {
          updatedCurrentImages[img.imageId] = img.imageUrl
        });        
        setCurrentImages(updatedCurrentImages);
      }
    }
    init();
  }, [i18n.language]);

  useEffect(() => {
    if (isXL) {
      setImgHeight(120);
    } else if (isLG) {
      setImgHeight(108);
    } else if (isMD) {
      setImgHeight(96);
    } else if (isSM) {
      setImgHeight(84);
    } else if (isXS) {
      setImgHeight(72);
    }
  }, [isLG, isMD, isSM, isXL, isXS]);

  const StyledBox = ({
    height,
    sx,
    children,
    isMobile,
    img,
    title,
    clickHandler,
  }) => {
    return (
      <Box
        style={{ borderColor: Colors.primary }}
        onClick={() => clickHandler()}
        sx={{
          ...sx,
          width: isMobile ? "150px" : height / 2 + "px",
          height: isMobile ? "150px" : height / 2 + "px",
          boxSizing: "border-box",
          backgroundColor: "#F4F8FF",
          m: { xs: 3, md: 0 },
          "&:hover": {
            backgroundColor: "#F4F8FF",
            opacity: [0.9, 0.8, 0.7],
          },
        }}
      >
        <Box
          height="70%"
          width="100%"
          sx={{ display: "flex", justifyContent: "center", alignItems: "end" }}
        >
          <img
            src={img}
            style={{ color: "#000" }}
            width={imgHeight}
            height={imgHeight}
          />
        </Box>
        <Box
          height="30%"
          width="100%"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="title3"
            sx={{
              fontFamily: "YouTubeSansBold",
              fontSize: "1rem",
              color: Colors.fonts.secondary,
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    );
  };
  return (
    <Box
      sx={{
        display: "inline-flex",
        flexWrap: "wrap",
        justifyContent: { xs: "center" },
        height: height != "auto" ? height + "px" : height,
        width: isMobile ? "100%" : height + "px",
      }}
    >
      <StyledBox
        height={height}
        sx={{ borderTop: 1, borderRight: 1}}
        isMobile={isMobile}
        img={currentImages['imgUnitPrice']}
        imgHeight={imgHeight}
        clickHandler={() => navigate("/unitPrices")}
        title={t("home.slider.features.unit_prices")}
      ></StyledBox>
      <StyledBox
        height={height}
        sx={{borderTop: 1, borderRight: 1}}
        isMobile={isMobile}
        img={currentImages['imgFundReturn']}
        imgHeight={imgHeight}
        clickHandler={() => navigate("/fundReturns")}
        title={t("home.slider.features.fund_returns")}
      ></StyledBox>
            <StyledBox
        height={height}
        sx={{ borderTop: 1, borderRight: 1,borderBottom: 1}}
        isMobile={isMobile}
        img={currentImages['imgSelectFund']}
        imgHeight={imgHeight}
        clickHandler={() => navigate("/selectFunds")}
        title={t("home.slider.features.selectFund")}
      ></StyledBox>
      <StyledBox
        height={height}
        sx={{ borderTop: 1, borderRight: 1,borderBottom: 1 }}
        isMobile={isMobile}
        img={currentImages['imgInvest']}
        imgHeight={imgHeight}
        clickHandler={() => navigate("/invest")}
        title={t("home.slider.features.invest")}
      ></StyledBox>

    </Box>
  );
};

export default withTranslation()(Fetures);
