import React from "react";
import { Icon } from "@mui/material";
import img from '../../asserts/icons/calling.svg';

export default function CallingIcon(props) {
  return (
    <Icon {...props} style={{minWidth:'30px', minHeight:'30px'}}>
      <img src={img} className="icon" />
    </Icon>
  );
}