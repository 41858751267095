import {
  Box,
  Container,
  Menu,
  styled,
  Toolbar,
  Typography,
  Link,
} from "@mui/material";
import React from "react";
import "./footerBottom.scss";
import { useTranslation, withTranslation } from "react-i18next";

const FooterBottom = () => {
  const { t } = useTranslation();
  const footerTitleRigth = [
    { id: "id", title: t("footer.footerBottom.titleRigth") },
  ];

  return (
    <Toolbar className="toolbarMain">
      <Box>
        {footerTitleRigth.map((footerTitleRigth) => {
          return (
            <Typography
              key={footerTitleRigth.id}
              variant="overline"
              noWrap
              component="label"
              href="/"
              sx={{
                mr: { xs: 0, md: 3 },
                display: { xs: "inline", md: "flex" },
                fontFamily: "YouTubeSansMedium",
                fontSize: { xs: "0.62rem", md: "1rem" },
                color: "#BABABA",
                textDecoration: "none",
                textTransform: "capitalize",
                height: "24px",
                alignItems: "center",
              }}
            >
              {footerTitleRigth.title}
            </Typography>
          );
        })}
      </Box>
      <Box sx={{ display: { xs: "inline", md: "flex" } }}>
        <Typography
          variant="overline"
          noWrap
          component="label"
          sx={{
            ml: 1,
            display: { xs: "inline", md: "flex" },
            fontFamily: "YouTubeSansMedium",
            fontSize: { xs: "0.9rem", md: "1rem" },
            color: "#BABABA",
            textDecoration: "none",
            textTransform: "capitalize",
            height: "24px",
            alignItems: "center",
          }}
        >
          {t("footer.footerBottom.titleLeft")}
        </Typography>

        <Link
          variant="overline"
          noWrap
          sx={{
            ml: 1,
            display: { xs: "inline", md: "flex" },
            fontFamily: "YouTubeSansMedium",
            fontSize: { xs: "0.9rem", md: "1rem" },
            color: "#BABABA",
            textDecoration: "underline",
            cursor: "pointer",
            textTransform: "capitalize",
            height: "24px",
            alignItems: "center",
          }}
          href="https://www.facebook.com/sameera.ekanayaka.33/about"
        >
          Sameera Software Solutions
        </Link>
      </Box>
    </Toolbar>
  );
};

export default withTranslation()(FooterBottom);
