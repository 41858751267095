import React from "react";
import FullWithContainer from "../../../components/ui-conponents/fullWidthContainer";
import ResponsiveAppBar from "./components/appbar";
import SliderContainer from "./components/sliderContainer";

const Header = ()=>{
    return (
        <FullWithContainer>
            <ResponsiveAppBar />
            <SliderContainer />
        </FullWithContainer>
    );
}

export default Header;
