import React from 'react';
import { lazy } from 'react';


// Project Imports

import Loadable from '../../components/utils/Loadable';
import MainLayout from '../../layouts/mainLayout';
// Renders
const Home = Loadable(lazy(() => import('../../pages/home/home')));
const AboutUs = Loadable(lazy(() => import('../../pages/aboutUs/AboutUs')));
const Staff = Loadable(lazy(() => import('../../pages/staff/staff')));
const BranchLocator = Loadable(lazy(() => import('../../pages/branchLocator/index')));

const UnitPrices = Loadable(lazy(() => import('../../pages/unitPrices')));
const FundReturns = Loadable(lazy(() => import('../../pages/fundReturns')));
const Invest = Loadable(lazy(() => import('../../pages/invest')));
const SelectFunds = Loadable(lazy(() => import('../../pages/selectFunds')));

const PageRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Home />
        },
        {
            path: '/about',
            element: <AboutUs />
        },
        {
            path: '/Staff',
            element: <Staff />
        },
        {
            path: '/branchLocator',
            element: <BranchLocator />
        },
        // {
        //     path: '/unitPrices',
        //     element: <UnitPrices />
        // },
        // {
        //     path: '/fundReturns',
        //     element: <FundReturns />
        // },
        // {
        //     path: '/invest',
        //     element: <Invest />
        // },
        // {
        //     path: '/selectFunds',
        //     element: <SelectFunds />
        // },
    ]
};

export default PageRoutes;
