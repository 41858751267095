import { Button, Container, useMediaQuery, useTheme, ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Routes from "./routes";
import theme from "./styles/theme";


function App() {

    return <ThemeProvider theme={theme}><Routes /><ToastContainer /></ThemeProvider>
}

export default App;
