import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import i18n from "i18next";
import { BrowserRouter } from "react-router-dom";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

import "./index.css";
import App from "./App";
import "./asserts/fonts/YouTubeSansBlack.otf";
import "./asserts/fonts/YouTubeSansRegular.otf";
import "./asserts/fonts/YouTubeSansBold.otf";
import "./asserts/fonts/YouTubeSansMedium.otf";
import "./asserts/fonts/YouTubeSansSemibold.otf";


const root = ReactDOM.createRoot(document.getElementById("root"));

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    supportedLngs: ['en','si','ta'],
    fallbackLng: "en",
    detection:{
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie']
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json'
    },
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });
const loading = (<h2>Loading...</h2>);
root.render(
  <Suspense fallback={loading}>
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
