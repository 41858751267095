import {
  colors,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../../../../components/logo/logo";
import { Colors } from "../../../../../styles/theme";
import { Box } from "@mui/system";
import RoundButton from "../../../../../components/ui-conponents/RoundButton";
import CallingIcon from "../../../../../asserts/icons/calling.svg";
import i18next from "i18next";
import { useTranslation, withTranslation } from "react-i18next";
const DrawerComp = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const aboutUsHandler = () => {
    navigate("/about");
  };
  const homeHandler = () => {
    navigate("/");
  };
  const investHandler = ()=>{
    navigate("/invest");
  }
  const contactHandler = ()=>{
    navigate("/contact");
  }

  const [open, setOpen] = useState(false);
  const pages = [
    { id: "home", title: t("header.appbarBody.title1"),clickFor: homeHandler  },
    { id: "services", title: t("header.appbarBody.title2") },
    { id: "howToInvest", title: t("header.appbarBody.title3"),clickFor: investHandler  },
  ];

  const headerPages = [
    { id: "aboutUs", title: t("header.appbarHeader.title2"), clickFor: aboutUsHandler },
    // { id: "quickLinks", title: t("header.appbarHeader.title5") },
    { id: "branchLocator", title: t("header.appbarHeader.title1") },
    { id: "contactUs", title: t("header.appbarHeader.title4"), clickFor: contactHandler  },
  ];

  const langs = [
    { id: "සි ංහල", code: "si", name: "සි ංහල", country_code: "sl" },
    { id: "தமிழ்", code: "ta", name: "தமிழ்", country_code: "in" },
    { id: "English", code: "en", name: "English", country_code: "us" },
  ];

  return (
    <>
      <Drawer {...props} open={open} onClose={() => setOpen(false)}>
        <List>
          {pages.map((page, index) => {
            return (
              <Box key={page.id}>
                {index > 0 ? <Divider /> : null}
                <ListItemButton
                onClick={page.clickFor}
                >
                  <ListItemIcon>
                    <ListItemText
                      key={page.id}
                      primaryTypographyProps={{
                        textTransform: "capitalize",
                        fontFamily: "YouTubeSansBold",
                        fontSize: "1.3rem",
                        color: Colors.primary,
                      }}
                    >
                      {page.title}
                    </ListItemText>
                  </ListItemIcon>
                </ListItemButton>
              </Box>
            );
          })}
        </List>
        <List>
          {headerPages.map((headerPages, index) => {
            return (
              <Box key={headerPages.id}>
                {index > 0 ? <Divider /> : null}
                <ListItemButton onClick={headerPages.clickFor}>
                  <ListItemIcon>
                    <ListItemText
                      key={headerPages.id}
                      primaryTypographyProps={{
                        textTransform: "capitalize",
                        fontFamily: "YouTubeSansBold",
                        fontSize: "1.1rem",
                        color: Colors.primary,
                      }}
                    >
                      {headerPages.title}
                    </ListItemText>
                  </ListItemIcon>
                </ListItemButton>
              </Box>
            );
          })}
          {langs.map(({ code, name, country_code, index }) => {
            return (
              <Box key={langs.id}>
                <Divider />
                <ListItemButton onClick={() => i18next.changeLanguage(code)}>
                  <ListItemIcon>
                    <ListItemText
                      key={langs.id}
                      primaryTypographyProps={{
                        textTransform: "capitalize",
                        fontFamily: "YouTubeSansBold",
                        fontSize: "1.1rem",
                        color: Colors.primary,
                      }}
                    >
                      {name}
                    </ListItemText>
                  </ListItemIcon>
                </ListItemButton>
              </Box>
            );
          })}
        </List>
      </Drawer>
      <IconButton
        {...props}
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={() => {
          setOpen(!open);
        }}
        color="inherit"
      >
        <MenuIcon />
        <Logo sx={{ display: { xs: "flex", md: "none" }, ml: 2 }} />
      </IconButton>

      <RoundButton
        variant="contained"
        sx={{
          display: { xs: "flex", md: "none" },
          backgroundColor: Colors.primary,
          fontFamily: "YouTubeSansMedium",
          fontSize: "1.3rem",
        }}
      >
        <img
          src={CallingIcon}
          style={{ width: "15px", height: "15px", marginRight: "10px" }}
        />
        <Typography sx={{ color: "#EBEBEB", fontSize: "12px" }}>
          +94(11)7602000
        </Typography>
      </RoundButton>
    </>
  );
};

export default withTranslation() (DrawerComp);
