const apiProtocall = "http";

//const apiUrl = "13.212.113.222";
//const apiUrl = "localhost";
const apiUrl = "44.223.26.233";
//const apiUrl = "ec2-54-237-198-160.compute-1.amazonaws.com";
const apiPort = "5000";



const constantparameters = {
  apiUrlWithPort: `${apiProtocall}://${apiUrl}:${apiPort}/api/v1/public`,
  apiUrl: `${apiProtocall}://${apiUrl}\\`,
  rootUrl: `${apiProtocall}://${apiUrl}:${apiPort}`,
};



export default constantparameters;