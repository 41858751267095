import { Box, Container, Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import FullWithContainer from "../../../../../components/ui-conponents/fullWidthContainer";
import Fetures from "./fetures";
import Slider from "./slider";



const SliderContainer = ()=>{
    const theme = useTheme();
    const isXL = useMediaQuery(theme.breakpoints.only('xl'));
    const isLG = useMediaQuery(theme.breakpoints.only('lg'));
    const isMD = useMediaQuery(theme.breakpoints.only('md'));
    const isSM = useMediaQuery(theme.breakpoints.only('sm'));
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [height, setHeight] = useState(480);
    
    useEffect(()=>{
        if(isXL){
            setHeight(420);
        }else if(isLG){
            setHeight(380)
        }else if(isMD){
            setHeight(360)
        }else if(isSM){
            setHeight(360)
        }
        else if(isXS){
            setHeight(240);
        }
    }, [isLG,isMD,isSM,isXL,isXS]);
    return (
        <div style={{paddingLeft:"50px",paddingRight:"50px"}}>
            <Box sx={{display: {xs: 'block', md: 'flex'}}}>
                <Box sx={{flexGrow:1, height:height+'px', width: 'auto', mb: {xs: 3, md: 0}}}><Slider height={height}/></Box>
                <Box sx={{height:isMobile ? 'auto' :height+'px', width: isMobile ? '100%' :height+'px'}}><Fetures isMobile={isMobile} height={isMobile ? 'auto' : height}/></Box>
            </Box>
        </div>
    );
}

export default SliderContainer;