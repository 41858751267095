import axios from "../../../../../../config/axios";
import configConstants from "../../../../../../config/constants";

const featureService = {  
  getImages: async (dataParams) => {
    try {
      const apiUrl = configConstants.apiUrlWithPort;
      const res = await axios.get(
        `${apiUrl}/layout/features/getImages`,
        {
          params: dataParams,
        }
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  },
  getFeatureImages: async (dataParams={langCode:'en'}) => {
    try {
      const apiUrl = configConstants.apiUrlWithPort;
      const res = await axios.get(
        `${apiUrl}/layout/featureImages`,
        {
          params: dataParams,
        }
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  },

  getFundReturnsImages: async (dataParams={langCode:'en'}) => {
    try {
      const apiUrl = configConstants.apiUrlWithPort;
      const res = await axios.get(
        `${apiUrl}/layout/fundReturnsImages`,
        {
          params: dataParams,
        }
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  },
  getInvestImages: async (dataParams={langCode:'en'}) => {
    try {
      const apiUrl = configConstants.apiUrlWithPort;
      const res = await axios.get(
        `${apiUrl}/layout/investImages`,
        {
          params: dataParams,
        }
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  },
  getSelectFundImages: async (dataParams={langCode:'en'}) => {
    try {
      const apiUrl = configConstants.apiUrlWithPort;
      const res = await axios.get(
        `${apiUrl}/layout/selectFundImages`,
        {
          params: dataParams,
        }
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  },

};



export default featureService;