import {
  Box,
  Container,
  Menu,
  styled,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";

const AppbarHeader = () => {
  const navigate = useNavigate();

  const aboutUsHandler = ()=>{
    navigate("/about");

  }
  const contactHandler = ()=>{
    navigate("/contact");

  }
  const branchLocatorHandler = ()=>{
    navigate("/branchLocator");

  }
  const { t } = useTranslation();
  const theme = useTheme();
    const isXL = useMediaQuery(theme.breakpoints.only('xl'));
    const isLG = useMediaQuery(theme.breakpoints.only('lg'));
    const isMD = useMediaQuery(theme.breakpoints.only('md'));
    const isSM = useMediaQuery(theme.breakpoints.only('sm'));
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));
    const [height, setHeight] = useState(48);
    const [fontSize, setFontSize] = useState(18);
    const [padding, setPadding] = useState(48);
    
    useEffect(()=>{
        if(isXL){
            setHeight(50);
            setPadding(48);
            setFontSize(18);
        }else if(isLG){
            setHeight(36);
            setPadding(48);
            setFontSize(16);
        }else if(isMD){
            setHeight(30);
            setPadding(36);
            setFontSize(14);
        }else if(isSM){
            setHeight(30);
            setPadding(12);
        }else if(isXS){
            setHeight(24);
            setPadding(12);
        }
    }, [isLG,isMD,isSM,isXL,isXS]);
    const pages = [
      { id: "aboutUs", title: t("header.appbarHeader.title2"), clickFor: aboutUsHandler },
      // { id: "quickLinks", title: t("header.appbarHeader.title5") },
      { id: "branchLocator", title: t("header.appbarHeader.title1"),clickFor: branchLocatorHandler },
      { id: "contactUs", title: t("header.appbarHeader.title4"),clickFor: contactHandler  },
    ];

  const langs = [
    {code: 'si', name: 'සි ංහල', country_code: 'sl'},
    {code: 'ta', name: 'தமிழ்', country_code: 'in'},
    {code: 'en', name: 'English', country_code: 'us'},
  ];
  return (
    <Toolbar
    sx={{justifyContent:'space-between'}}
      style={{
        minHeight: height,
        background: "#231F20",
        paddingLeft: padding,
        paddingRight: padding,
      }}
    >
        <Box sx={{ display: "flex"}}>
          {pages.map((page) => {
            return (
              <Typography
                key={page.id}
                variant="overline"
                noWrap
                component="label"
                href="/"
                sx={{
                  mr: 6,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "YouTubeSansMedium",
                  fontSize: fontSize+'px',
                  color: "inherit",
                  textDecoration: "none",
                  cursor: "pointer",
                  textTransform: "capitalize",
                  height: "24px",
                  alignItems: "center",
                  letterSpacing:0
                }}
                onClick={page.clickFor}
              >
                {page.title}
              </Typography>
            );
          })}
        </Box>
        <Box sx={{ display: "flex"}}>
            {langs.map(({code, name , country_code})=>{
                return <Typography
                key={country_code}
                variant="overline"
                noWrap
                component="label"
                href="/"
                onClick={()=>i18next.changeLanguage(code)}
                sx={{
                  ml: 3,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "YouTubeSansMedium",
                  fontSize: fontSize+'px',
                  color: "inherit",
                  textDecoration: "none",
                  cursor: "pointer",
                  textTransform: "capitalize",
                  height: "24px",
                  alignItems: "center"
                }}
              >
                {name}
              </Typography>
            })}
        </Box>
    </Toolbar>
  );
};

export default withTranslation() (AppbarHeader);
