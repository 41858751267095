import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Carousel from "react-material-ui-carousel";
import FullWithContainer from "../../../../../components/ui-conponents/fullWidthContainer";
import cookies from "js-cookie";
import { withTranslation } from "react-i18next";
import sliderService from "./services/sliderService";
import configConstants from "../../../../../config/constants";

const Slider = ({ i18n, height }) => {
  const [autoPlay, setAutoPlay] = useState(true);
  const [indicators, setIndicators] = useState(true);
  const [animation, setAnimation] = useState("fade");
  const [timeout, setTimeoutFunc] = useState(50000);
  const [navButtonsAlwaysInvisible, setNavButtonsAlwaysInvisible] =
    useState(false);
  const [navButtonsAlwaysVisible, setNavButtonsAlwaysVisible] = useState(false);
  const [cycleNavigation, setCycleNavigation] = useState(true);
  const slides = useRef([]);
  const [currentSlides, setCurrentSlides] = useState([]);

  useEffect(() => {
    if (slides && slides.current.length == 0) {
      sliderService
        .getImages({})
        .then((res) => {
          slides.current = res.map((item) => {
            return { Name: item.imageTitle, Image: item.imageUrl,langCode:item.langCode };
          });
          setCurrentSlides(
            slides.current.filter((i) => i.langCode == i18n.language)
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setCurrentSlides(
        slides.current.filter((i) => i.langCode == i18n.language)
      );
    }
  }, [i18n.language]);

  const toggleAutoPlay = () => {
    setAutoPlay(!autoPlay);
  };

  const toggleIndicators = () => {
    setIndicators(!indicators);
  };

  const toggleNavButtonsAlwaysVisible = () => {
    setNavButtonsAlwaysVisible(!navButtonsAlwaysVisible);
  };

  const toggleNavButtonsAlwaysInvisible = () => {
    setNavButtonsAlwaysInvisible(!navButtonsAlwaysInvisible);
  };

  const toggleCycleNavigation = () => {
    setCycleNavigation(!cycleNavigation);
  };

  const changeAnimation = (event) => {
    setAnimation(event.target.value);
  };

  const changeTimeout = (event, value) => {
    setTimeout(value);
  };

  const Banner = ({ item, height }) => {
    return (
      <Grid item xs={12} key={item.Name}>
        <CardMedia
          className="Media"
          image={item.Image}
          title={item.Name}
          style={{ height: height + "px" }}
        ></CardMedia>
      </Grid>
    );
  };

  const items = [
    {
      Name: "Floral Vase",
      Image: "http://localhost:5000/public/images/slider/en/slider_02.jpg",
    },
    {
      Name: "Macbook Pro",
      Image: "http://localhost:5000/public/images/slider/en/slider_03.jpg",
    },
    {
      Name: "iPhone",
      Image: "http://localhost:5000/public/images/slider/en/slider_04.jpg",
    },
    {
      Name: "Washing Machine WX9102",
      Image: "http://localhost:5000/public/images/slider/en/slider_05.jpg",
    },
    {
      Name: "Learus Vacuum Cleaner",
      Image: "http://localhost:5000/public/images/slider/en/slider_06.jpg",
    },
  ];

  return (
    <FullWithContainer>
      <Carousel
        height={height + "px"}
        className="Example"
        autoPlay={autoPlay}
        animation={animation}
        indicators={indicators}
        timeout={timeout}
        cycleNavigation={cycleNavigation}
        navButtonsAlwaysVisible={navButtonsAlwaysVisible}
        navButtonsAlwaysInvisible={navButtonsAlwaysInvisible}
        next={(now, previous) =>
          console.log(
            `Next User Callback: Now displaying child${now}. Previously displayed child${previous}`
          )
        }
        prev={(now, previous) =>
          console.log(
            `Prev User Callback: Now displaying child${now}. Previously displayed child${previous}`
          )
        }
        onChange={(now, previous) =>
          console.log(
            `OnChange User Callback: Now displaying child${now}. Previously displayed child${previous}`
          )
        }
        // fullHeightHover={false}
        // navButtonsProps={{style: {backgroundColor: 'cornflowerblue', borderRadius: 0}}}
        // navButtonsWrapperProps={{style: {bottom: '0', top: 'unset', }}}
        indicatorContainerProps={{
          style: {
            position: "absolute",
            bottom: "10px",
            zIndex: 999,
            margin: "0px",
          },
        }}
        // NextIcon='next'
      >
        {currentSlides.map((item, index) => {
          return (
            <Banner
              item={item}
              key={index}
              contentPosition={item.contentPosition}
              height={height}
            />
          );
        })}
      </Carousel>
    </FullWithContainer>
  );
};

export default withTranslation()(Slider);
